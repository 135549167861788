

















































































































import { Vue, Component } from 'vue-property-decorator'

import { Instance } from '../../../shared/models/instance.model'
import { getConfig } from '../shared/config'
import { listInstances } from '../shared/instance-http'
import faSmile from '@fortawesome/fontawesome-free-solid/faSmile'
import faMeh from '@fortawesome/fontawesome-free-solid/faMeh'
import faFrown from '@fortawesome/fontawesome-free-solid/faFrown'
import debounce from 'lodash/debounce'
import ISO6391 from 'iso-639-1'

@Component
export default class InstanceList extends Vue {
  columns = [
    {
      label: 'Name',
      field: 'name',
      sortable: true,
      tdClass: 'name'
    },
    {
      label: 'Url',
      field: 'host',
      sortable: true,
      tdClass: 'host'
    },
    {
      label: 'Version',
      field: 'version',
      sortable: true,
      tdClass: 'version'
    },
    {
      label: 'Users',
      field: 'totalUsers',
      sortable: true,
      type: 'number'
    },
    {
      label: 'Local events',
      field: 'totalLocalEvents',
      sortable: true,
      type: 'number'
    },
    {
      label: 'Local groups',
      field: 'totalLocalGroups',
      sortable: true,
      type: 'number'
    },
    {
      label: 'Following',
      field: 'totalInstanceFollowing',
      sortable: true,
      type: 'number'
    },
    {
      label: 'Followers',
      field: 'totalInstanceFollowers',
      sortable: true,
      type: 'number'
    },
    {
      label: 'Total events',
      field: 'totalEvents',
      sortable: true,
      type: 'number'
    },
    {
      label: 'Total groups',
      field: 'totalGroups',
      sortable: true,
      type: 'number'
    },
    {
      label: 'Signup',
      field: 'signupAllowed',
      type: 'boolean',
      sortable: true
    },
    {
      label: 'Languages',
      field: 'languages',
      type: 'array',
      sortable: false
    },
    {
      label: 'Location',
      field: 'country',
      type: 'string',
      sortable: true
    },
    {
      label: 'Health',
      field: 'health',
      sortable: true,
      type: 'number'
    }
  ]

  rows: Instance[] = []
  totalRecords: number | null = null
  formatter = null;

  perPage = 10

  searchOptions = {
    enabled: true,
    placeholder: 'Search among instances'
  }

  paginationOptions = {
    enabled: true,
    perPage: this.perPage
  }

  onSearch = debounce(this.onSearchImpl.bind(this), 500)

  topTextHtml = ''

  private search = ''
  private sort = '-createdAt'
  private currentPage = 1

  async mounted () {
    this.loadData()

    const config = await getConfig()
    this.topTextHtml = config.instanceClientWarning

    try {
      // @ts-ignore
      this.formatter = new Intl.ListFormat(undefined, { style: 'long', type: 'conjunction' });
    } catch (e) {
      console.log('browser does not support ListFormat');
    }
  }

  getUrl (host: string) {
    return 'https://' + host
  }

  getIcon (health: number) {
    if (health > 90) return faSmile
    if (health > 50) return faMeh
    return faFrown
  }

  getIconColor (health: number) {
    if (health > 98) return '#08cd36'
    if (health > 90) return '#81c307'
    if (health > 70) return '#cfb11b'
    if (health > 50) return '#e67a3c'
    if (health > 20) return '#e7563c'

    return '#f44141'
  }

  getInstanceNameTitle (instance: Instance) {
    return instance.name + '\nAdded on ' + new Date(instance.createdAt).toLocaleString()
  }

  onPageChange (params: { currentPage: number }) {
    this.currentPage = params.currentPage
    this.loadData()
  }

  onPerPageChange (params: { currentPerPage: number }) {
    this.perPage = params.currentPerPage
    this.currentPage = 1
    this.loadData()
  }

  onSortChange (params: any) {
    const newSort = params[0]

    this.sort = newSort.type === 'asc' ? '' : '-'
    this.sort += newSort.field

    this.loadData()
  }

  private onSearchImpl (params: { searchTerm: string }) {
    this.search = params.searchTerm
    this.loadData()
  }

  private async loadData () {
    const response = await listInstances({
      page: this.currentPage,
      perPage: this.perPage,
      sort: this.sort,
      search: this.search
    })

    this.rows = response.data
    this.totalRecords = response.total
  }
}
