































































import { Component, Vue } from 'vue-property-decorator'

import { InstanceStats as InstanceStatsModel } from '../../../shared/models/instance-stats.model'
import { GlobalStats } from '../../../shared/models/global-stats.model'
import { getInstanceStats } from '../shared/instance-http'

import * as bytes from 'bytes'
import InstanceStatsHistory from './InstanceStatsHistory.vue'

@Component({
  components: {
    InstanceStatsHistory
  },
  filters: {
    formatNumber: (value: string) => value.toLocaleString(),

    formatBytes: (value: string) => bytes.format(parseInt(value, 10))
  }
})
export default class InstanceStats extends Vue {
  stats: GlobalStats = null

  async mounted () {
    this.stats = await getInstanceStats()
  }
}
