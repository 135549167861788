





















import { Component, Vue } from 'vue-property-decorator'
import { addInstance } from '../shared/instance-http'
import { httpErrorToString } from '../shared/utils'

@Component
export default class InstanceAdd extends Vue {
  host = ''
  err = ''
  messageSuccess = ''
  loading = false

  onSubmit (event: Event) {
    event.preventDefault()

    this.loading = true
    this.err = ''
    this.messageSuccess = ''

    addInstance(this.host)
      .then(() => {
        this.loading = false
        this.messageSuccess = `${this.host} has been successfully added.`
        this.host = ''
      })
      .catch(err => {
        this.loading = false
        this.err = httpErrorToString(err)
      })
  }
}
