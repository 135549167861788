

























import { Component, Vue } from 'vue-property-decorator'
import { getGlobalStatsHistory } from '../shared/instance-http'

import { Area, Cartesian, Legend, Tooltip, XAxis, YAxis } from 'laue'
import { GlobalStats } from '../../../shared/models/global-stats.model'

@Component({
  components: {
    LaCartesian: Cartesian,
    LaArea: Area,
    LaXAxis: XAxis,
    LaYAxis: YAxis,
    LaTooltip: Tooltip,
    LaLegend: Legend
  }
})
export default class InstanceStatsHistory extends Vue {
  charts: any[] = []
  total!: number

  async mounted () {
    const history = await getGlobalStatsHistory()
    const data = history.data.reverse()

    this.charts = [
      this.chartBuilder([ 'Instances' ], data, [ 'totalInstances' ]),
      this.chartBuilder([ 'Events' ], data, [ 'totalEvents' ]),

      this.chartBuilder([ 'Users' ], data, [ 'totalUsers']),
      this.chartBuilder([ 'Groups' ], data, [ 'totalGroups' ]),

    ]

    this.total = data.length
  }

  formatNumber (value: number) {
    const dictionary = [
      { max: 1000, type: '' },
      { max: 1000000, type: 'K' },
      { max: 1000000000, type: 'M' },
      { max: 1000000000000, type: 'G' },
      { max: 1000000000000000, type: 'T' },
    ]

    const format = dictionary.find(d => value < d.max) || dictionary[dictionary.length - 1]
    const calc = Math.floor(value / (format.max / 1000))

    return `${calc}${format.type}`
  }

  interval (i: number) {
    const points = Math.ceil(this.total/4)

    return i === 0 || i === this.total - 1 || i % points === 0
  }

  private chartBuilder (labels: string[], data: { date: string, stats: GlobalStats }[], keys: (keyof GlobalStats)[]) {
    const result: any = {}
    let i = 1

    for (const label of labels) {
      Object.assign(result, {
        ['label' + i]: label,
      })

      i++
    }

    return Object.assign(result, {
      data: data.map(d => {
        const base = {
          date: new Date(d.date).toLocaleDateString()
        }

        let i = 1

        for (const key of keys) {
          Object.assign(base, {
            ['value' + i]: d.stats[key]
          })

          i++
        }

        return base
      })
    })
  }
}
